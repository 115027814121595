import React, { useState, createContext, useEffect, useContext } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import i18n from "../i18next";
import API from "../helpers/api";
import { ThemeContext } from "./themeContext";
import { Clan } from "../models";

const ClanContext = createContext({
  clanFetched: false as boolean,
  clansFetched: false as boolean,
  eventsFetched: false as boolean,

  clan: {} as Clan,
  clans: [] as Array<Clan>,
  events: [] as Array<Event>,
  futureEvents: [] as Array<Event>,
  fetchEvents: () => {},
});

const ClanContextConsumer = ClanContext.Consumer;

interface ClanContextProviderProps {
  children: JSX.Element;
}

const ClanContextProvider = ({
  children,
}: ClanContextProviderProps): JSX.Element => {
  const route = useRouteMatch("/:clanSlug");
  const history = useHistory();
  const [clanFetched, setClanFetched] = useState(false);
  const [clansFetched, setClansFetched] = useState(false);
  const [eventsFetched, setEventsFetched] = useState(false);

  const [clan, setClan] = useState({} as Clan);
  const [clans, setClans] = useState([] as Array<Clan>);
  const [events, setEvents] = React.useState([] as Array<Event>);
  const [futureEvents, setFutureEvents] = React.useState([] as Array<Event>);
  const { setThemeColor, setLoading } = useContext(ThemeContext);

  const fetchClan = async (clanId: number) => {
    async function fetchClan() {
      try {
        let clan = await API.get("Clans", clanId);
        setClan(clan);
        setThemeColor(clan.clanColor);
      } catch {
        setClan({} as Clan);
      }
    }

    async function fetchLanguage() {
      let language = (
        await API.getAll("Languages", {
          Clan_id: clanId,
          _start: 0,
          _end: 5000,
        })
      )[0]?.content;
      i18n.addResourceBundle("en", "translation", language, true, true);
    }

    if (!process.env.REACT_APP_STANDALONE_ID) setLoading(true);
    await Promise.all([fetchClan(), fetchLanguage()]);
    if (!process.env.REACT_APP_STANDALONE_ID) setLoading(false);
    return;
  };

  const fetchEvents = async () => {
    try {
      const events =
        (await API.getAll("Events", {
          Clan_id: clan.id,
          _start: 0,
          _end: 10,
          _sort: "date",
          _order: "DESC",
        })) || ([] as Array<Event>);
      setEvents(
        events.filter((event: Event) => new Date(event.date) < new Date())
      );
      setFutureEvents(
        events.filter((event: Event) => new Date(event.date) > new Date())
      );
    } catch {
      setEvents([] as Array<Event>);
    }
    setEventsFetched(true);
  };

  useEffect(() => {
    async function fetchClans() {
      try {
        setClans(
          await API.getAll("Clans", {
            _start: 0,
            _end: 5000,
          })
        );
        setClansFetched(true);
      } catch {
        setClans([]);
        setClansFetched(true);
      }
    }
    if (process.env.REACT_APP_STANDALONE_ID) {
      fetchClan(parseInt(process.env.REACT_APP_STANDALONE_ID)).then(() =>
        setClanFetched(true)
      );
    } else if (!route?.params?.clanSlug) {
      fetchClans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchClans() {
      try {
        setClans(
          await API.getAll("Clans", {
            _start: 0,
            _end: 5000,
          })
        );
        setClansFetched(true);
      } catch {
        setClans([]);
        setClansFetched(true);
      }
    }

    async function fetchClan() {
      const clans = await API.getAll("Clans", {
        slug: route?.params?.clanSlug,
      });
      if (clans[0]) {
        let language = (
          await API.getAll("Languages", {
            Clan_id: clans[0].id,
            _start: 0,
            _end: 5000,
          })
        )[0]?.content;
        i18n.addResourceBundle("en", "translation", language, true, true);
        setClan(clans[0]);
        setThemeColor(clans[0].clanColor);
        setClanFetched(true);
      } else {
        history.push("/");
      }
    }

    if (!process.env.REACT_APP_STANDALONE_ID) {
      if (!clan?.id && route?.params?.clanSlug && !clanFetched) {
        fetchClan();
      }

      // if (clan?.slug && clan?.slug !== route?.params?.clanSlug) {
      //   API.getAll("Clans", { slug: route?.params?.clanSlug }).then((clans => {
      //     if (clans[0]) {
      //       fetchClan(clans[0].id).then(() => setClanFetched(true))
      //     } else {
      //       history.push("/clans");
      //     }
      //   }))
      // }

      if (clan?.id && !route?.params?.clanSlug) {
        fetchClans();
        setClan({} as Clan);
        setClanFetched(false);
        fetchEvents();
        setEventsFetched(false);
        setEvents([] as Array<Event>);
        setFutureEvents([] as Array<Event>);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route?.params]);

  return (
    <ClanContext.Provider
      value={{
        clans,
        clanFetched,
        clansFetched,
        clan,
        events,
        futureEvents,
        eventsFetched,
        fetchEvents,
      }}
    >
      {children}
    </ClanContext.Provider>
  );
};

export { ClanContextProvider, ClanContextConsumer, ClanContext };
