import React from "react";
import {
  Filter,
  Pagination,
  TabbedShowLayout,
  Tab,
  List,
  Show,
  Create,
  Datagrid,
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  NumberField,
  NumberInput,
  BooleanField,
  BooleanInput,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source="question" alwaysOn />
    <NumberField source="order" alwaysOn />
  </Filter>
);
export const QuestionList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
    sort={{ field: "order", order: "ASC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="question" />
      <NumberField source="order" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);

export const QuestionShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Question">
        <TextField source="question" />
        <NumberField source="order" />
        <BooleanField source="active" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const QuestionEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="question" multiline />
      <NumberInput source="order" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);

export const QuestionCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="question" />
      <NumberInput source="order" />
      <BooleanInput source="active" />
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
