import React, { FunctionComponent, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import CenteredGrid from "../../components/Shared/CenteredGrid";
import { MentorProgram } from "../../models";
import Submission from "../MentorLogger/Submission";

interface TrialContainerProps {
  mentorProgram: MentorProgram;
}
const TrialContainer: FunctionComponent<TrialContainerProps> = ({
  mentorProgram,
}) => {
  return (
    <CenteredGrid container spacing={3}>
      <>
        <Grid item xs={12}>
          <Submission mentorProgram={mentorProgram} />
        </Grid>
      </>
    </CenteredGrid>
  );
};
export default TrialContainer;
