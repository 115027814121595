import React, { useContext, FunctionComponent } from "react";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import CenteredGrid from "../../components/Shared/CenteredGrid";
import { ThemeContext } from "../../contexts/themeContext";
import { UserContext } from "../../contexts/userContext";
import { ClanContext } from "../../contexts/clanContext";
import { Member, MentorProgram, Mentor } from "../../models";
import { SubmissionContext } from "../../contexts/submissionContext";

interface SubmissionProps {
  mentorProgram: MentorProgram;
}
const Submission: FunctionComponent<SubmissionProps> = ({ mentorProgram }) => {
  const { t } = useTranslation();
  const { clan } = useContext(ClanContext);
  const { members, fetchMembers, createMentor } = useContext(SubmissionContext);
  const { mentorSubmitted, setMentorSubmitted, submitting } =
    useContext(ThemeContext);
  const [mentor, setMentor] = React.useState({} as Member);
  const [attendees, setAttendees] = React.useState([] as Array<Member>);
  const [screenshot, setScreenshot] = React.useState("");
  const [completions, setCompletions] = React.useState(0);
  const { user } = useContext(UserContext);
  const skipMentor = ["Combat Achievements", "5 Purple Trial"].includes(
    mentorProgram?.name
  );
  const resetForm = () => {
    setScreenshot("");
    setCompletions(0);
    setAttendees([] as Array<Member>);
    setMentor({} as Member);
    setMentorSubmitted(false);
  };

  const mentorObject = {
    Member_id: mentor.id,
    MentorProgram_id: mentorProgram.id,
    name: `${mentorProgram.name} - ${mentor.username || "No mentor"}`,
    completions,
    screenshot,
    submitter: user.id,
    date: new Date(),
    attendees: attendees.map((helper) => helper.id),
    Clan_id: clan.id,
  } as Mentor;

  return !mentorSubmitted ? (
    <CenteredGrid container spacing={1}>
      <>
        <Grid item xs={12}>
          <Typography variant="h5">{mentorProgram.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{mentorProgram.description}</Typography>
        </Grid>
        {skipMentor ? null : (
          <Grid item xs={12}>
            <Autocomplete
              options={mentorProgram.teachers}
              autoSelect
              getOptionLabel={(option) => option.username || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label={t("mentorLogger.mentorLabel")}
                  placeholder={t("mentorLogger.mentorPlaceholder")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              onChange={(_event, mentor) => {
                setMentor(mentor || {});
              }}
              onInputChange={(_event, value) => {
                fetchMembers(value);
              }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={members}
            autoHighlight
            autoSelect
            getOptionLabel={(option) => option.username || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={t("mentorLogger.helperLabel")}
                placeholder={t("mentorLogger.helperPlaceholder")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            onChange={(_event, attendees) => {
              setAttendees(attendees || {});
            }}
            onInputChange={(_event, value) => {
              fetchMembers(value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="screenshot"
            label={t("mentorLogger.screenshotLabel")}
            placeholder={t("mentorLogger.screenshotPlaceholder")}
            fullWidth
            value={screenshot}
            onChange={(e) => setScreenshot(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="completions"
            label={t("mentorLogger.completionsLabel")}
            placeholder={t("mentorLogger.completionsPlaceholder")}
            fullWidth
            type="number"
            value={completions}
            onChange={(e) => setCompletions(parseInt(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={
              !mentorProgram.id || !screenshot || !completions || submitting
            }
            variant="outlined"
            onClick={() => createMentor(mentorObject)}
          >
            {t("mentorLogger.submit")}
          </Button>
        </Grid>
      </>
    </CenteredGrid>
  ) : (
    <CenteredGrid container spacing={1}>
      <>
        <Grid item xs={12}>
          <Typography variant="caption">{t("mentorLogger.success")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={() => resetForm()}>
            {t("mentorLogger.submitAnother")}
          </Button>
        </Grid>
      </>
    </CenteredGrid>
  );
};
export default Submission;
