import React, {
  useContext,
  FunctionComponent,
  useState,
  useEffect,
} from "react";
import Grid from "@material-ui/core/Grid";
import Layout from "../../components/Layout/Layout";
import { ClanContext } from "../../contexts/clanContext";
import CustomTabs from "../../components/Requirements/CustomTabs";
import TrialContainer from "../../components/TrialLogger/TrialContainer";
import { SubmissionContext } from "../../contexts/submissionContext";

const TrialLogger: FunctionComponent = () => {
  const { clan } = useContext(ClanContext);
  const {
    mentorPrograms,
    mentorProgramsFetched,
    fetchMentorPrograms,
    checklistsFetched,
    fetchChecklists,
  } = useContext(SubmissionContext);
  const [background, setBackground] = useState(mentorPrograms[0]?.artwork);

  useEffect(() => {
    if (clan && clan.id && !mentorProgramsFetched && !checklistsFetched) {
      fetchMentorPrograms();
      fetchChecklists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorProgramsFetched, checklistsFetched]);

  return (
    <Layout
      loading={!mentorProgramsFetched || !checklistsFetched}
      background={background}
      title={"Infernal Trial Logger"}
    >
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12}>
          <TrialContainer
            mentorProgram={mentorPrograms.find(
              (mentorProgram) => mentorProgram.name === "5 Purple Trial"
            )}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TrialLogger;
