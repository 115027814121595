import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "./ListItem";
import List from "@material-ui/core/List";
import styles from "../../assets/jss/components/headerStyle";
import { SubmissionContext } from "../../contexts/submissionContext";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemDefault from "@material-ui/core/ListItem";
import ListIcon from "@material-ui/icons/List";

export default function Hiscore({ baseUrl }) {
  const {
    bossesFetched,
    fetchAllBosses,
    skillsFetched,
    fetchSkills,
    recordsFetched,
    fetchRecords,
    pointCategories,
    bosses,
    records,
    skills,
  } = useContext(SubmissionContext);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const [mode, setMode] = React.useState("");

  useEffect(() => {
    if (!bossesFetched && !skillsFetched && !recordsFetched) {
      fetchAllBosses();
      fetchSkills();
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bossesFetched, skillsFetched, recordsFetched]);

  return (
    <List className={classes.list}>
      <ListItemDefault button onClick={handleClick}>
        <ListItemText primary={"Hiscores"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemDefault>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          <ListItemDefault
            button
            onClick={() =>
              mode !== "points" ? setMode("points") : setMode("")
            }
          >
            <ListItemText primary={"Points"} />
            {mode === "points" ? <ExpandLess /> : <ExpandMore />}
          </ListItemDefault>
          <Collapse in={mode === "points"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <>
                <ListItem
                  to={`${baseUrl}/hiscores/points/overall`}
                  text={"Overall"}
                  Icon={ListIcon}
                />
                <ListItem
                  to={`${baseUrl}/hiscores/points/community`}
                  text={"Community"}
                  Icon={ListIcon}
                />
                {pointCategories.map((pointCategory) => (
                  <ListItem
                    key={pointCategory.id}
                    to={`${baseUrl}/hiscores/points/${pointCategory.id}`}
                    text={pointCategory.name}
                    Icon={ListIcon}
                  />
                ))}

                <ListItem
                  to={`${baseUrl}/hiscores/points/pets`}
                  text={"Pets"}
                  Icon={ListIcon}
                />

                <ListItem
                  to={`${baseUrl}/hiscores/points/diary`}
                  text={"Diary"}
                  Icon={ListIcon}
                />
              </>
            </List>
          </Collapse>
          <ListItemDefault
            button
            onClick={() => (mode !== "kc" ? setMode("kc") : setMode(""))}
          >
            <ListItemText primary={"KC"} />
            {mode === "kc" ? <ExpandLess /> : <ExpandMore />}
          </ListItemDefault>
          <Collapse in={mode === "kc"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <>
                <ListItem
                  to={`${baseUrl}/hiscores/kc/overall`}
                  text={"Overall"}
                  Icon={ListIcon}
                />
                {bosses.map((boss) => (
                  <ListItem
                    key={boss.id}
                    to={`${baseUrl}/hiscores/kc/${boss.id}`}
                    text={boss.name}
                    Icon={ListIcon}
                  />
                ))}
              </>
            </List>
          </Collapse>

          <ListItemDefault
            button
            onClick={() => (mode !== "ehb" ? setMode("ehb") : setMode(""))}
          >
            <ListItemText primary={"EHB"} />
            {mode === "ehb" ? <ExpandLess /> : <ExpandMore />}
          </ListItemDefault>
          <Collapse in={mode === "ehb"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <>
                <ListItem
                  to={`${baseUrl}/hiscores/ehb/overall`}
                  text={"Overall"}
                  Icon={ListIcon}
                />
                {bosses.map((boss) => (
                  <ListItem
                    key={boss.id}
                    to={`${baseUrl}/hiscores/ehb/${boss.id}`}
                    text={boss.name}
                    Icon={ListIcon}
                  />
                ))}
              </>
            </List>
          </Collapse>

          <ListItemDefault
            button
            onClick={() =>
              mode !== "skills" ? setMode("skills") : setMode("")
            }
          >
            <ListItemText primary={"Skills"} />
            {mode === "skills" ? <ExpandLess /> : <ExpandMore />}
          </ListItemDefault>
          <Collapse in={mode === "skills"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <>
                {[
                  skills.find((x) => x.name === "Overall"),
                  ...skills.filter((x) => x.name !== "Overall"),
                ]
                  .filter(Boolean) // Remove undefined values if "Overall" isn't found
                  .map((skill) => (
                    <ListItem
                      key={skill.id}
                      to={`${baseUrl}/hiscores/skills/${skill.id}`}
                      text={skill.name}
                      Icon={ListIcon}
                    />
                  ))}
              </>
            </List>
          </Collapse>

          <ListItemDefault
            button
            onClick={() => (mode !== "time" ? setMode("time") : setMode(""))}
          >
            <ListItemText primary={"Time"} />
            {mode === "time" ? <ExpandLess /> : <ExpandMore />}
          </ListItemDefault>
          <Collapse in={mode === "time"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <>
                {bosses.map((boss) =>
                  records
                    .filter((record) => record.Boss_id === boss.id)
                    .map((record) => (
                      <ListItem
                        key={record.id}
                        to={`${baseUrl}/hiscores/time/${record.id}`}
                        text={`${boss.name} - ${record.record}`}
                        Icon={ListIcon}
                      />
                    ))
                )}
              </>
            </List>
          </Collapse>
        </List>
      </Collapse>
    </List>
  );
}
